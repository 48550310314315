
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider as ApolloHookProvider  } from '@apollo/client';
import {  ApolloProvider } from "react-apollo";
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import en_US from 'antd/lib/locale-provider/en_US';

import {  createApolloClient } from "./createApolloClient";
import './index.css';
import App from './App';


import history from './utils/history'

import store from './store';



const apolloClient = createApolloClient();

ReactDOM.render(
     <ApolloProvider client={apolloClient}>
           <ApolloHookProvider client={apolloClient}>
                <Provider store={store}>
                <Router history={history}>
                    <ConfigProvider locale={en_US}>
                        <App />
          
                    </ConfigProvider>
                </Router>
            </Provider >

            </ApolloHookProvider>
    </ApolloProvider>
     ,
    document.getElementById('root'));


