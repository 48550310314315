import React from "react";
import { connect } from "react-redux";
import { Query } from "react-apollo";

import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import "./App.less";

import PrivateRoute from "./components/PrivateRoute";

import LoadableComponent from "./utils/LoadableComponent";
import { loadUser, logoutAction } from "./actions/auth";

import { getCookie } from "./utils/auth";
import { GET_AUTH_USER } from "../src/graphql/user";
import Loading from "./components/Loading";

// import store from './store';
const Index = LoadableComponent(import("./pages/Index"));
const Login = LoadableComponent(import("./pages/Login"));
const Register = LoadableComponent(import("./pages/Register"));

const cookieName = process.env.TOKEN || "ssbu-t";

const App = ({ loadUser, logoutAction }) => {
  return (
    <Query query={GET_AUTH_USER}>
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error)
          return (
            <p>
              Error auth User ....
              {console.log("here is Error:", error.message)}
            </p>
          );

        const { getAuthUser } = data;

        if (getAuthUser && getAuthUser.role !== 111) {
          logoutAction();
          //  return <Redirect to={'/'} />

          return (window.location.href =
            process.env.REACT_APP_FRONT_END_URL ||
            "http://localhost:8080" + "/login");
        }

        if (getCookie(cookieName)) {
          loadUser(getAuthUser);
        }

        return (
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <PrivateRoute path="/" component={Index} />
              {/* <Route path='/create-post' component={CreatePost} /> */}
            </Switch>
          </Router>
        );
      }}
    </Query>
  );
};

const MapStateToProps = (state) => ({});
export default connect(MapStateToProps, { loadUser, logoutAction })(App);
