import gql from 'graphql-tag';

/**
 * Records to select from user
 */
const userPayload = `
 id
 username
 email
 role
 createdAt
`;

/**
 * get auth user
 */
export const GET_AUTH_USER = gql`
 query  {
  getAuthUser{
   ${userPayload}

  }
}
`;

/**
 * sign up user
 */
export const SIGN_UP = gql`
 mutation($input: SignUpInput!) {
       SignUp(input: $input) {
            token
       }
 }
`;
/**
 * sign in user
 */
export const SIGN_IN = gql`
mutation($input: SignInInput!) {
      SignIn(input: $input) {
            token
      }
}
`;