

import { getMainDefinition } from "apollo-utilities";
import { setContext } from '@apollo/client/link/context';
import { ApolloClient,  ApolloLink, Observable, split, InMemoryCache } from '@apollo/client';
import {  onError } from "@apollo/client/link/error";

import { createUploadLink } from 'apollo-upload-client';

import { getCookie } from './utils/auth'; 


// dotenv.config();

const tokenKey = process.env.REACT_APP_TOKEN || 'ssbu-t'
/**
 * Helper functions that handles error cases
 */
const handleErrors = () => {
  return onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });
};


export const createApolloClient = (apiUrl) => {
    
    
    const errorLink = handleErrors();


/**
 * Creates a Apollo Link, that adds authentication token to request
 */
// const createAuthLink = () => {

//   const request = (operation) => {
//     const  token = getCookie(tokenKey);
//     operation.setContext({
//       headers: {
//         authorization: token ? `Bearer ${token}` : "",
//       },
//     });
//   };

//   return new ApolloLink(
//     (operation, forward) =>
//       new Observable((observer) => {
//         let handle;
//         Promise.resolve(operation)
//           .then((oper) => request(oper))
//           .then(() => {
//             handle = forward(operation).subscribe({
//               next: observer.next.bind(observer),
//               error: observer.error.bind(observer),
//               complete: observer.complete.bind(observer),
//             });
//           })
//           .catch(observer.error.bind(observer));

//         return () => {
//           if (handle) handle.unsubscribe();
//         };
//       })
//   );
// };

// const authLink = createAuthLink();

const authLink = setContext((_, { headers }) => {
    const  token = getCookie(tokenKey);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
})

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_API_URL || 'http://localhost:5000/graphql'});


const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  uploadLink
);




return new ApolloClient({
    cache: new InMemoryCache(),

     link: ApolloLink.from([
        errorLink,
        authLink,
        uploadLink
      ]),
      credentials: 'include'
})
}


